html,
body,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;
}

body {
    position: relative;
    height: 100%;
    min-height: 100%;
    font-family: "Raleway", sans-serif;
    font-size: 0.6em;
}

.container,
.columns-editor {
    height: 100%;
    min-height: 100%;
}

.max-height {
    height: 100%;
}
